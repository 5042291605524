.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  margin: 0 !important;
  padding: 0px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input:-webkit-autofill {
  appearance: none;
  background-color: #fff !important; /* Override the autofill background */
  color: inherit !important; /* Inherit the text color */
  -webkit-text-fill-color: inherit !important; /* Override the text color */
  box-shadow: 0 0 0 1000px #fff inset !important; /* Prevent any autofill shadows */
  transition: background-color 5000s ease-in-out 0s !important; /* Smooth transition */
}
.css-ls303m-MuiListItemIcon-root {
  min-width: 38px !important;
}
.css-1dr5nhj-MuiListItemIcon-root {
  min-width: 38px !important;
}
.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}
.css-1toxriw-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0 !important;
  position: relative;
  outline: 0;
}
.hudJpJ {
  background-color: #e8e8e8 !important;
}
textarea::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}
textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}